import {
  AspectRatio,
  Container,
  createStyles,
  Group,
  Image,
  SimpleGrid,
  Text,
  ThemeIcon,
  Title,
} from '@mantine/core';
import useStyles from './About.styles';
// import IMAGES from './images';

interface FeatureImage {
  image: string;
  title: React.ReactNode;
  description: React.ReactNode;
}

interface FeaturesImagesProps {
  supTitle: React.ReactNode;
  description: React.ReactNode;
  data: FeatureImage[];
}

export default function About() {
  const { classes } = useStyles();

  return (
    <Container size={700} className={classes.wrapper}>
      <Text className={classes.supTitle}>USE CASES</Text>
      {/* 
      <Title className={classes.title} order={2}>
        Scry is <span className={classes.highlight}>not</span> just for editors
      </Title> */}

      <Container size={660} p={0} style={{ marginBottom: '2em' }}>
        <Text color='dimmed' className={classes.description}>
          Scry has applications ranging from surveillance to video editing, to
          finance and product research, to education. Anyone—from a single
          student to an army base to big bank—parsing through troves of
          audio-video data will benefit from Scry.
        </Text>
      </Container>

      <div
        // position='center'
        style={{ paddingTop: '56.25%', height: '0px', position: 'relative' }}
      >
        {/* <AspectRatio ratio={1920 / 1080} sx={{ maxWidth: 300 }}> */}
        <iframe
          // className='w-full'
          title='demo'
          // width='100%'
          // height=''
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: '0',
            left: '0',
          }}
          frameBorder={0}
          src='https://www.youtube.com/embed/_kaez2GRTw8'
        />
        {/* </AspectRatio> */}
      </div>
    </Container>
  );
}
