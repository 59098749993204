import React from 'react';
import ReactDOM from 'react-dom/client';
import { Helmet } from 'react-helmet';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import App from './App';
import About from './components/about/About';
import Layout from './components/Layout';
import MantineWrapper from './components/MantineWrapper';
import NotFound from './components/NotFound';
import './index.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <Layout>
        <App />
      </Layout>
    ),
    errorElement: <NotFound />,
  },
  {
    path: '/about',
    element: (
      <Layout>
        <About />
      </Layout>
    ),
    errorElement: <NotFound />,
  },
]);

root.render(
  <React.StrictMode>
    <Helmet>
      <script
        defer
        data-domain='getscry.tech'
        src='https://analytics.govindgnana.com/js/plausible.js'
      ></script>
    </Helmet>
    <MantineWrapper>
      <RouterProvider router={router} />
    </MantineWrapper>
  </React.StrictMode>
);
