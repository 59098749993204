import {
  Badge,
  Button,
  Center,
  Group,
  Loader,
  Popover,
  Select,
  Skeleton,
  Stack,
  Text,
  TextInput,
  Title,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useViewportSize } from '@mantine/hooks';
import { IconPlaneDeparture, IconSearch } from '@tabler/icons';
import axios from 'axios';
import hash from 'object-hash';
import React, { useEffect, useRef, useState } from 'react';
import YouTube, { YouTubePlayer, YouTubeProps } from 'react-youtube';
import useStyles from './App.styles';

interface Phrase {
  score?: number;
  start: number;
  end: number;
  speaker: number;
  text: string;
  color: number[];
}

const THRESHOLD = 2;

function App() {
  const { classes } = useStyles();
  let instance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
  });
  const theme = useMantineTheme();
  const { height, width } = useViewportSize();

  const outer = useRef<HTMLDivElement>(null);

  const [videoHeight, setVideoHeight] = useState(0);
  const [player, setPlayer] = useState<YouTubePlayer>(null);
  const [videos, setVideos] = useState({});
  const [videoId, setVideoId] = useState('');
  const [transcript, setTranscript] = useState<Phrase[]>([]);
  const [search, setSearch] = useState<Phrase[]>([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [transcriptLoading, setTranscriptLoading] = useState(false);
  const [checked, setChecked] = useState(true);
  const [speakers, setSpeakers] = useState<
    {
      speaker: number;
      color: number[];
    }[]
  >();
  const [seeking, setSeeking] = useState(false);
  const [seekPhrase, setSeekPhrase] = useState('');

  if (outer.current && videoHeight === 0)
    setVideoHeight(outer.current?.clientHeight);

  const semSearchForm = useForm({
    initialValues: {
      query: '',
    },
  });
  const imageSearchForm = useForm({
    initialValues: {
      query: '',
    },
  });

  const parseTimestamp = (timestamp: number) => {
    timestamp = timestamp / 1000;
    const hours = Math.floor(timestamp / (60 * 60));
    const minutes = Math.floor((timestamp - hours * 60 * 60) / 60);
    const seconds = Math.floor(timestamp - (hours * 60 * 60 + minutes * 60));
    return hours === 0
      ? `${minutes.toString().padStart(2, '0')}:${seconds
          .toString()
          .padStart(2, '0')}`
      : `${hours}:${minutes.toString().padStart(2, '0')}:${seconds
          .toString()
          .padStart(2, '0')}`;
  };

  const toColor = (score: number) => {
    const LOW = 23;
    const HIGH = 34;
    if (score < LOW) score = LOW;
    if (score > HIGH) score = HIGH;
    const normalized = (score - LOW) / (HIGH - LOW);
    const red = (1 - normalized) * 255;
    const green = normalized * 255;
    return `rgb(${red}, ${green}, 0)`;
  };

  useEffect(() => {
    const init = async () => {
      instance = axios.create({
        baseURL: process.env.REACT_APP_API_BASE_URL,
      });
      const videos = await instance.get('videos');
      setVideos(videos.data.videos);
    };
    init();
  }, []);

  const mapToOutput = (input: Phrase[]) => {
    return (
      <>
        {input.map((line) => {
          return (
            <span
              className='rounded-lg p-2 text-black'
              style={{
                fontFamily: 'Inter Var',
                backgroundColor: `rgb(${line.color[0] * 255}, ${
                  line.color[1] * 255
                }, ${line.color[2] * 255})`,
              }}
            >
              {seekPhrase && hash(line) === seekPhrase && seeking && (
                <Loader style={{ marginRight: '0.5em' }} size='xs' />
              )}
              {line.score && (
                <Tooltip label='semantic similarity score'>
                  <Badge
                    style={{
                      fontWeight: '800',
                      marginRight: '0.5em',
                      backgroundColor: toColor(line.score),
                      color: 'black',
                    }}
                    onClick={() => {
                      if (player) player.seekTo(line.start / 1000);
                    }}
                  >
                    {line.score.toFixed(2)}
                  </Badge>
                </Tooltip>
              )}
              <span
                style={{ fontWeight: '600', marginRight: '0.5em' }}
                className='hover:text-blue-800 hover:underline'
                onClick={() => {
                  if (player) player.seekTo(line.start / 1000);
                  console.log('niggas');
                  setSeekPhrase(hash(line));
                }}
              >
                {line.text}
              </span>
              <span style={{ fontWeight: '800' }}>
                <span
                  className='hover:text-blue-800 hover:underline'
                  onClick={() => {
                    if (player) player.seekTo(line.start / 1000);
                    setSeekPhrase(hash(line));
                  }}
                >
                  {parseTimestamp(line.start)}
                </span>{' '}
                -{' '}
                <span
                  className='hover:text-blue-800 hover:underline'
                  onClick={() => {
                    if (player) player.seekTo(line.start / 1000);
                    setSeekPhrase(hash(line));
                  }}
                >
                  {parseTimestamp(line.end)}
                </span>
              </span>
            </span>
          );
        })}
      </>
    );
  };

  const generateSkeleton = () => {
    return (
      <>
        {Array.apply(null, Array(15)).map((_) => (
          <>
            <Skeleton
              height={8}
              width={Math.floor(Math.random() * 10) * 10 + '%'}
              radius='xl'
            />
            <br />
          </>
        ))}
      </>
    );
  };

  const cock = Object.entries(videos);
  //@ts-ignore
  console.log(cock);
  return (
    <>
      {width < 1024 ? (
        <div className='text-center'>
          <Title order={2}>
            Scry does not work on mobile devices.
            <br /> Please visit from a wider screen device to access scry.
          </Title>
        </div>
      ) : (
        <>
          {JSON.stringify(videos) !== '{}' ? (
            <>
              <Stack>
                <Group position='center'>
                  <Select
                    label='Choose A Sample Video'
                    placeholder='Pick one'
                    data={Object.entries(videos)
                      //@ts-ignore
                      .sort((entry1, entry2) => {
                        // @ts-ignore
                        const name1 = entry1[1]['name'];
                        // @ts-ignore
                        const name2 = entry2[1]['name'];
                        if (name1 < name2) return -1;
                        else if (name1 > name2) return 1;
                        return 0;
                      })
                      .map((entry) => {
                        const id: string = entry[0];
                        // @ts-ignore
                        const info: { name: string } = entry[1];
                        return { value: id, label: info['name'] };
                      })}
                    searchable
                    onChange={async (id) => {
                      setTranscript([]);
                      setSearch([]);
                      setSearchLoading(true);
                      if (id !== null) setVideoId(id);
                      // videoRef?.current?.setAttribute(
                      //   'src',
                      //   // `${process.env.REACT_APP_API_BASE_URL}/static/${id}.mp4`
                      //   `http://www.youtube.com/embed/${id}`
                      // );
                      setTranscriptLoading(true);
                      const transcript = await instance.get('transcript', {
                        params: { id },
                      });
                      setTranscriptLoading(false);
                      setTranscript(transcript.data.transcript);
                      const phrases: Phrase[] = transcript.data.transcript;
                      const speakers: { speaker: number; color: number[] }[] =
                        [];
                      for (let phrase of phrases) {
                        if (
                          !speakers.find(
                            (speaker) => speaker.speaker === phrase.speaker
                          )
                        )
                          speakers.push({
                            speaker: phrase.speaker,
                            color: phrase.color,
                          });
                      }
                      setSpeakers(speakers);
                      setSearchLoading(false);
                      if (player) {
                        // player.onStateChange = (state: any) => {
                        //   console.log(state);
                        // };
                        // player.on
                        // videoRef.current.onseeking = () => {
                        //   setSeeking(true);
                        // };
                        // videoRef.current.onseeked = () => {
                        //   setSeeking(false);
                        // };
                      }
                    }}
                  />
                </Group>
                {transcript.length > 0 && (
                  <div className='flex flex-shrink'>
                    {/* <Group position="center" mb="2em"> */}
                    <div className='w-1/4 lg:w-1/2'>
                      <form
                        onSubmit={imageSearchForm.onSubmit(async (values) => {
                          setSearchLoading(true);
                          const videos = await instance.get('image-search', {
                            params: { id: videoId, q: values.query },
                          });
                          console.log(videos.data);

                          const timestamps: number[] = videos.data.timestamps;
                          const scores: number[] = videos.data.values;
                          // timestamps.sort();

                          console.log('sorted timestamps', timestamps);

                          const phrases: Phrase[] = [];
                          let oldTimestamp = null;
                          for (let i = 0; i < timestamps.length; i++) {
                            const timestamp = timestamps[i];
                            // if (
                            //   oldTimestamp !== null &&
                            //   Math.abs(timestamp - oldTimestamp) <= THRESHOLD
                            // ) {
                            //   oldTimestamp = timestamp;
                            //   continue;
                            // }
                            let found = false;
                            for (let phrase of transcript) {
                              if (
                                timestamp * 1000 > phrase.start &&
                                timestamp * 1000 < phrase.end &&
                                !phrases.includes(phrase)
                              ) {
                                found = true;
                                phrase.start =
                                  (oldTimestamp === null
                                    ? timestamp
                                    : oldTimestamp) * 1000;
                                phrase.end = timestamp * 1000;
                                phrase.score = scores[i];
                                phrases.push(phrase);
                              }
                            }
                            if (!found) {
                              phrases.push({
                                start:
                                  (oldTimestamp === null
                                    ? timestamp
                                    : oldTimestamp) * 1000,
                                end: timestamp * 1000,
                                // TODO: lol
                                speaker: 100,
                                text: '<Silent Segment>',
                                color: [255, 255, 255],
                                score: scores[i],
                              } as Phrase);
                            }
                            // oldTimestamp = timestamp;
                          }
                          console.log(phrases);
                          setSearch(phrases);
                          console.log(phrases);
                          setSearchLoading(false);
                        })}
                      >
                        <Popover
                          width={300}
                          position='top'
                          shadow='md'
                          withArrow
                        >
                          <Popover.Target>
                            <TextInput
                              icon={<IconSearch size={18} stroke={1.5} />}
                              style={{
                                paddingLeft: '2em',
                                // paddingRight: '1em',
                                // paddingRight: '2em',
                                marginBottom: '1em',
                                ...(transcript && { marginTop: '2em' }),
                              }}
                              radius='xl'
                              size='md'
                              placeholder='Search through video'
                              rightSectionWidth={42}
                              {...imageSearchForm.getInputProps('query')}
                            />
                          </Popover.Target>
                          <Popover.Dropdown>
                            <Text size='sm'>
                              Semantically search through video
                              <br />
                              ex: "yellow outfit man in mountain range"
                            </Text>
                          </Popover.Dropdown>
                        </Popover>
                      </form>
                    </div>

                    <div className='w-1/4 lg:w-1/2'>
                      <form
                        onSubmit={semSearchForm.onSubmit(async (values) => {
                          setSearchLoading(true);
                          const matchedPhrases = (
                            await instance.get('text-search', {
                              params: { id: videoId, q: values.query },
                            })
                          ).data.similar;

                          const phrases: Phrase[] = [];
                          for (let matchedPhrase of matchedPhrases) {
                            for (let phrase of transcript) {
                              if (phrase.text === matchedPhrase) {
                                phrases.push(phrase);
                              }
                            }
                          }
                          setSearch(phrases);
                          setSearchLoading(false);
                          // setSearch()
                        })}
                      >
                        <Popover
                          width={300}
                          position='top'
                          shadow='md'
                          withArrow
                        >
                          <Popover.Target>
                            <TextInput
                              icon={<IconSearch size={18} stroke={1.5} />}
                              radius='xl'
                              size='md'
                              placeholder='Search through audio'
                              rightSectionWidth={42}
                              style={{
                                paddingLeft: '1em',
                                // paddingRight: '2em',
                                marginBottom: '1em',
                                ...(transcript && { marginTop: '2em' }),
                              }}
                              {...semSearchForm.getInputProps('query')}
                            />
                          </Popover.Target>
                          <Popover.Dropdown>
                            <Text size='sm'>
                              Semantically search through transcript
                              <br />
                              ex: "privacy and security"
                            </Text>
                          </Popover.Dropdown>
                        </Popover>
                      </form>
                    </div>
                    <div className='w-1/2 lg:w-full'>
                      {speakers && speakers.length > 0 && (
                        <Stack
                          spacing='xs'
                          style={{ marginLeft: '2em', marginBottom: '1em' }}
                        >
                          <Text>Click On Color To Search By Speaker</Text>
                          <Button.Group>
                            {speakers?.map((speaker) => (
                              <Button
                                onClick={() => {
                                  const phrases: Phrase[] = [];
                                  for (let phrase of transcript) {
                                    if (phrase.speaker === speaker.speaker) {
                                      phrases.push(phrase);
                                    }
                                  }
                                  setSearch(phrases);
                                }}
                                style={{
                                  backgroundColor: `rgb(${
                                    speaker.color[0] * 255
                                  }, ${speaker.color[1] * 255}, ${
                                    speaker.color[2] * 255
                                  })`,
                                  width: '1px',
                                }}
                              ></Button>
                            ))}
                          </Button.Group>
                        </Stack>
                      )}
                    </div>
                  </div>
                )}
                {transcriptLoading ? (
                  <Group position='center' mt='0.5em'>
                    <Loader size={50} />
                  </Group>
                ) : (
                  <Center>
                    {transcript.length === 0 && (
                      <Title order={2}>
                        Choose one of the demo videos from above to begin using
                        scry
                      </Title>
                    )}
                  </Center>
                )}
              </Stack>

              <div className='flex mb-4'>
                <div className='w-1/2 lg:w-full'>
                  {/* <AspectRatio ratio={16 / 9}> */}
                  {/* <div className={classes.player}> */}
                  {/* <video
                    hidden={transcript.length === 0}
                    controls
                    ref={videoRef}
                    style={{ width: '100%', paddingLeft: '2em' }}
                  /> */}
                  {videoId && (
                    <div
                      ref={outer}
                      style={{
                        height: '100%',
                        paddingLeft: '2em',
                        // display: 'flex',
                      }}
                    >
                      <YouTube
                        videoId={videoId}
                        opts={{
                          width: '100%',
                          // height: outer.current?.clientHeight,
                          height: videoHeight,
                          playerVars: {
                            // https://developers.google.com/youtube/player_parameters
                            autoplay: 1,
                          },
                        }}
                        onReady={(event) => {
                          setPlayer(event.target);
                        }}
                      />
                    </div>
                  )}
                  {/* </div> */}
                  {/* </AspectRatio> */}
                </div>
                <div className='w-1/2 lg:w-full'>
                  <Group>
                    {search.length !== 0 && (
                      <Button
                        style={{ marginBottom: '1em', marginLeft: '2em' }}
                        onClick={() => {
                          setChecked(!checked);
                        }}
                        size='md'
                      >
                        {checked ? 'Back To Transcript' : 'Show Search Results'}
                      </Button>
                    )}
                  </Group>

                  {search.length !== 0 || seeking ? (
                    <div
                      style={{
                        overflowY: 'scroll',
                        height:
                          'calc((100vw / 2 - 1em) * (9 / 16) - 2em - 42px)',
                      }}
                    >
                      <Group
                        position='left'
                        spacing='xs'
                        className={classes.transcript}
                      >
                        {transcript.length > 0 &&
                          (searchLoading
                            ? generateSkeleton()
                            : mapToOutput(
                                search.length > 0 && checked
                                  ? search
                                  : transcript
                              ))}
                      </Group>
                    </div>
                  ) : (
                    <div
                      style={{
                        overflowY: 'scroll',
                        height: 'calc((100vw / 2 - 1em) * (9 / 16) - 1em)',
                      }}
                    >
                      <Group
                        position='left'
                        spacing='xs'
                        className={classes.transcript}
                      >
                        {transcript.length > 0 &&
                          (searchLoading
                            ? generateSkeleton()
                            : mapToOutput(
                                search.length > 0 && checked
                                  ? search
                                  : transcript
                              ))}
                      </Group>
                    </div>
                  )}
                </div>
              </div>
            </>
          ) : (
            <Group position='center' mt='2em'>
              <Loader size={50} />
            </Group>
          )}
        </>
      )}
    </>
  );
}

export default App;
