import {
  Box,
  Burger,
  Button,
  Divider,
  Drawer,
  Group,
  Header,
  ScrollArea,
  Switch,
  Title,
  useMantineColorScheme,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconMoonStars, IconPokeball, IconSun } from '@tabler/icons';
import { Link } from 'react-router-dom';
import useStyles from './Header.styles';

export default function TheHeader() {
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] =
    useDisclosure(false);
  const { classes, theme } = useStyles();
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();

  return (
    <Box>
      <Header height={60} px='md'>
        <Group position='apart' sx={{ height: '100%' }}>
          <Group
            sx={{ height: '100%' }}
            spacing={0}
            className={classes.hiddenMobile}
          >
            <Title order={1}>Scry</Title>
            <IconPokeball size={30} />
          </Group>
          <Group spacing={5} className={classes.links}>
            <Link to='/' className={classes.link}>
              Home
            </Link>
            <Link to='/about' className={classes.link}>
              About
            </Link>
          </Group>
          <Group className={classes.hiddenMobile}>
            <Switch
              checked={colorScheme === 'dark'}
              onChange={() => toggleColorScheme()}
              size='lg'
              onLabel={<IconSun color={theme.white} size={20} stroke={1.5} />}
              offLabel={
                <IconMoonStars
                  color={theme.colors.gray[6]}
                  size={20}
                  stroke={1.5}
                />
              }
            />
          </Group>

          <Burger
            opened={drawerOpened}
            onClick={toggleDrawer}
            className={classes.hiddenDesktop}
          />
        </Group>
      </Header>

      <Drawer
        opened={drawerOpened}
        onClose={closeDrawer}
        size='100%'
        padding='md'
        title='Scry'
        className={classes.hiddenDesktop}
        zIndex={1000000}
      >
        <ScrollArea sx={{ height: 'calc(100vh - 60px)' }} mx='-md'>
          <Divider
            my='sm'
            color={theme.colorScheme === 'dark' ? 'dark.5' : 'gray.1'}
          />

          <Link to='/' style={{ textDecoration: 'none' }}>
            <a className={classes.link}>Home</a>
          </Link>

          <Link to='/about' style={{ textDecoration: 'none' }}>
            <a className={classes.link}>About</a>
          </Link>

          <Divider
            my='sm'
            color={theme.colorScheme === 'dark' ? 'dark.5' : 'gray.1'}
          />
        </ScrollArea>
      </Drawer>
    </Box>
  );
}
