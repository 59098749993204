import React from 'react';
import TheHeader from './header/Header';

function Layout(props: any) {
  return (
    <>
      <TheHeader />
      {props.children}
    </>
  );
}

export default Layout;
