import { createStyles } from '@mantine/core';

export default createStyles((theme) => ({
  player: {
    maxWidth: '50%',
    [theme.fn.smallerThan('lg')]: {
      maxWidth: '62%',
    },
    [theme.fn.smallerThan('md')]: {
      maxWidth: '75%',
    },
    [theme.fn.smallerThan('sm')]: {
      maxWidth: '87%',
    },
    [theme.fn.smallerThan('xs')]: {
      maxWidth: '100%',
    },
  },
  transcript: {
    maxWidth: '75%',
    marginLeft: '2em',
  },
}));
